import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "src/utils/Seo"
import { RichText } from "prismic-reactjs"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"
import BlogArchiveFilter from "../components/Blog/BlogArchiveFilter"
import Breadcrumbs from "src/utils/Breadcrumbs"
import GatsbyLink from "src/utils/GatsbyLink"

const ArchivePostItem = props => {
  return (
    <Link
      to={`/our-blog/${props.uid}/`}
      className={`group ${
        props.index < 2 && props.currentPage === 1 ? "col-span-3" : "col-span-2"
      } text-left border-l border-gold mb-12`}
      title={`${props.data.title.text}`}
    >
      <Fade>
        <Img
          fluid={props.data.featured_image.fluid}
          alt={props.data.title.text}
          className="-ml-px transition duration-500 ease-in-out group-hover:opacity-70 max-w-full h-0 pb-[60%]"
        />
        <div className="p-6 pb-0">
          <date className="text-darkgreysecond text-xs inline-block tracking-normal">
            {props.data.post_date} - {props.tags.join(", ")}
          </date>
          <h2 className="text-darkgrey font-bold text-xs tracking-widest uppercase my-6 group-hover:text-gold duration-500 ease-in-out">
            {props.data.title.text}
          </h2>
          {props.index < 2 && props.currentPage === 1 && (
            <div className={`description line-clamp-3`}>
              <RichText
                render={props.data.description.richText}
                serializeHyperlink={GatsbyLink}
              />
            </div>
          )}
        </div>
      </Fade>
    </Link>
  )
}

const ArchivePostsContainer = props => {
  const currentPage = props.currentPage
  const href = props.categorySlug
    ? `/our-blog/category/${props.categorySlug}/${currentPage + 1}/`
    : `/our-blog/${currentPage + 1}/`
  // Logic for next page button (chevron-right)
  const next =
    currentPage < props.numPages ? (
      <li className="inline-block">
        <Fade>
          <Link
            className={`flex justify-center items-center text-darkgrey text-xs rounded-full h-6 w-6 font-semibold`}
            to={`${href}/`}
          >
            <i className="fas fa-chevron-right mr-2"></i>
          </Link>
        </Fade>
      </li>
    ) : (
      ""
    )
  return (
    <div className="container pt-12 blog-items">
      <div className="grid grid-cols-1 lg:grid-cols-6 lg:gap-6">
        {props.data.map((post, index) => {
          return (
            <ArchivePostItem
              {...post.node}
              key={index}
              index={index}
              currentPage={currentPage}
            />
          )
        })}
      </div>
      <nav aria-label="Page navigation" className="mt-12">
        <ul className="text-center space-x-2">
          {Array.from({ length: props.numPages }, (_, i) => (
            <li className="inline-block" key={i}>
              <Fade>
                <Link
                  className={`flex justify-center items-center ${
                    currentPage === i + 1
                      ? "bg-gold text-white"
                      : "text-darkgrey"
                  } text-xs rounded-full h-6 w-6 font-semibold`}
                  key={`pagination-number${i + 1}`}
                  to={`/our-blog/${
                    props.categorySlug ? `category/${props.categorySlug}/` : ""
                  }${i === 0 ? "" : i + 1}/`}
                >
                  <span>{i + 1}</span>
                </Link>
              </Fade>
            </li>
          ))}
          {next}
        </ul>
      </nav>
    </div>
  )
}

const ArchiveBlogPost = props => {
  const crumbs = [
    { label: "Advice", href: "/advice" },
    { label: "Our Blog", href: "/internal-doors/styles", active: true },
  ]

  return (
    <article className="blog-post">
      <Seo title={"Our Blog | News, Insights And More"} />
      <div className="py-5">
        <Breadcrumbs data={crumbs} theme="light" />
      </div>
      <main className={`bg-offwhite`}>
        <div className="container text-center pt-12">
          <Fade>
            <h1 className="font-display text-gold rfs:text-5xl">
              {props.pageContext.title}
            </h1>
            <div className="w-12 separator mt-5"></div>
          </Fade>
        </div>
        <BlogArchiveFilter />
        <ArchivePostsContainer
          data={props.data.allPrismicBlogPost.edges}
          currentPage={props.pageContext.currentPage}
          numPages={props.pageContext.numPages}
          categorySlug={props.pageContext.categorySlug}
        />
      </main>
    </article>
  )
}

export default ArchiveBlogPost

export const ArchiveBlogPostQuery = graphql`
  query ArchiveBlogPostQuery($skip: Int!, $limit: Int!) {
    allPrismicBlogPost(
      sort: { fields: data___post_date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          uid
          tags
          data {
            post_date(formatString: "DD MMM YYYY")
            title {
              text
            }
            featured_image {
              alt
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
            description {
              richText
            }
          }
        }
      }
    }
  }
`
