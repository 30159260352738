import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Fade from "react-reveal/Fade"
import ScrollContainer from "react-indiana-drag-scroll"

const BlogArchiveFilter = props => {
  const data = useStaticQuery(graphql`
    query BlogArchiveFilterQuery {
      allPrismicBlogPost {
        group(field: tags) {
          fieldValue
        }
      }
    }
  `)

  return (
    <div className="w-full bg-white py-6">
      <div className="container py-0 overflow-x-auto">
        <Fade>
          <ScrollContainer hideScrollbars={true} horizontal={true}>
            <div className="space-x-4 w-max m-auto overflow-x-scroll">
              <Link
                to="/our-blog/"
                className="btn font-bold text-xxs whitespace-nowrap"
                activeClassName="active"
              >
                All posts
              </Link>
              {data.allPrismicBlogPost.group.map((item, index) => {
                const slug = item.fieldValue.replace(" ", "-").toLowerCase()
                return (
                  <Link
                    to={`/our-blog/category/${slug}/`}
                    className="btn font-bold text-xxs whitespace-nowrap"
                    activeClassName="active"
                  >
                    {item.fieldValue}
                  </Link>
                )
              })}
            </div>
          </ScrollContainer>
        </Fade>
      </div>
    </div>
  )
}

export default BlogArchiveFilter
